import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_filter_statuses = _resolveComponent("dropdown-filter-statuses")!
  const _component_dropdown_filter_radio = _resolveComponent("dropdown-filter-radio")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    "search-placeholder": "Search",
    "show-filters": "",
    "show-buttons-text": "",
    "hide-columns-additional-buttons": ""
  }, {
    "filter-line-details": _withCtx(({ updateRadioValue, updateModelValue }) => [
      _createVNode(_component_dropdown_filter_statuses, {
        modelValue: _ctx.filters.campaignSentMessagesStatusFilter.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.campaignSentMessagesStatusFilter.value) = $event)),
        options: _ctx.filters.campaignSentMessagesStatusFilter.options,
        name: "Status",
        "search-placeholder": "Search status"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.countries.value
      }, _ctx.filters.countries, {
        name: "Country",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('countries', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('countries', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"])
    ]),
    _: 1
  }))
}